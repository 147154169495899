import { useContext, useEffect, useState } from "react";
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { useSearchParams } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { postFormData } from "../../utils/api-data";
import { ISubmitData } from "../../types/data-model";
import { IAppText } from "../../types/app";
import { LanguageContext } from "../Layout/Layout";

const SignedComponent = () => {

    const sessionId = window.sessionStorage.getItem("aml_session_id");  
    const ref = window.sessionStorage.getItem("aml_ref_id");

    const [searchParams] = useSearchParams();
    const [success, setSuccess] = useState<boolean | undefined>(false);
    const [error, setError] = useState<boolean | undefined>(false);
    const [canceled, setCanceled] = useState<boolean | undefined>(false);
    const [appText, setAppText] = useState<IAppText | undefined>(undefined);
    const context = useContext(LanguageContext);

    useEffect(() => {       
        
        const submit = async (submitDataObj : ISubmitData, sessionId: string) => {
            const result = await postFormData(submitDataObj, sessionId);

            try {
                if(result.status === 'ok') {
                    setSuccess(true);                  
                }
                else {
                    setError(true);
                }
            }
            catch(err) {
                setError(true);
            }
            finally {
                window.sessionStorage.clear(); 
            }
              
        }

        const appText : IAppText = require(`../assets/${context.language}_app_text.json`);
        setAppText(appText)  

        if(sessionId && ref) {
            
            if(searchParams.has("status")) {
                
                const status = searchParams.get('status');  
                if(status === 'canceled') {
                    setCanceled(true);
                }
                else {
                    
                    const signer_token = searchParams.get('signer_token');  
                    const signing_token = searchParams.get('signing_token');
                    const ref = window.sessionStorage.getItem("aml_ref_id");
                    const formData = window.sessionStorage.getItem("aml_data"); 
                    
                    if(signer_token && signing_token && ref && formData) {
                        
                        const submitDataObj : ISubmitData = {
                            signer_token: signer_token,
                            signing_token: signing_token,
                            ref: ref ,
                            form_data: formData
                        }

                        submit(submitDataObj, sessionId);
                    }
                    else {
                        setError(true);
                    }         
                }
            }

        }

    }, [ref, sessionId, searchParams, context.language]);

    const successContainer = () => {
        return <Typography variant="h5" color={"textPrimary"}>
                {appText?.SURVEY_COMPLETE_OK}
            </Typography>
    }

    const canceledContainer = () => {
        return <Typography variant="h5" color={"textPrimary"}>
                    {appText?.SURVEY_COMPLETE_CANCELLED}
                </Typography>
    }

    const errorContainer = () => {
        return <Typography variant="h5" color={"textPrimary"}>
                    {appText?.SURVEY_COMPLETE_ERROR}
                </Typography>
    }

    return (
        <Container className="content">
            <Box p={5} id="main-container">
                { success && successContainer() }
                { error && errorContainer() }
                { canceled && canceledContainer() }
            </Box>
            
        </Container>
    )
}

export default SignedComponent