import { useEffect, useState, useCallback, useContext, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles';
import { Model, surveyLocalization } from 'survey-core';
import "survey-core/survey.i18n";
import { Survey } from 'survey-react-ui';
import { SurveyPDF } from "survey-pdf";
import 'survey-core/defaultV2.min.css';
import './survey.css'
import { createDokobitSigning, decryptRefToken, postFormData, validateToken } from '../../utils/api-data';
import { IDokobitSigningResponse, IRefTokenDecryptResponse, ISessionIdVerifyResponse, ISubmitData } from "../../types/data-model";
import { IQuestionResult } from "../../types/components";
import { Buffer } from 'buffer';
import { LanguageContext } from "../Layout/Layout";
import { IAppText } from "../../types/app";
import { LegalRoles } from "../../utils/roles";
import Typography from "@mui/material/Typography";
import { getBase64Str } from "../../utils/utils";

const SurveyComponent = () => {

    const sessionId = window.sessionStorage.getItem("aml_session_id");  
    const ref = window.sessionStorage.getItem("aml_ref_id");
    const authToken = window.sessionStorage.getItem("aml_token");

    const [refToken, setRefToken] = useState<IRefTokenDecryptResponse | null>(null);
    const [survey, setSurvey] = useState<Model | undefined>(undefined);
    const [appText, setAppText] = useState<IAppText | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean | undefined>(false);

    const context = useContext(LanguageContext);
    const [searchParams] = useSearchParams();
    let legalRoles = useRef<LegalRoles>(new LegalRoles());
    let surveyData = useRef<any>();
    let surveyModel = useRef<Model>();

    const savePDF = useCallback(async (result : Model, surveyJson : any) => {

        const submit = async (submitDataObj : ISubmitData, sessionId: string) => {
            
            const result = await postFormData(submitDataObj, sessionId);

            if(result.status !== 'ok') {
                setError(true);                  
            } 
            
            window.sessionStorage.clear(); 
        }

        const exportToPdfOptions = {
            fontSize: 12,
            haveCommercialLicense: true
        };

        const surveyPDF = new SurveyPDF(surveyJson, exportToPdfOptions);
        surveyPDF.mode = "display";
        surveyPDF.locale = context.language;
        surveyPDF.data = result.data;
        surveyPDF.questionsOnPageMode = "singlePage";

        var data : IQuestionResult[] = [];
        for(var key in result.data) {
            const question = result.getQuestionByName(key);
            let questionResult;
            if(!question) continue;
            if(typeof(result.data[key]) === "object") {
                questionResult = { 
                    type: "array", 
                    val: new Array<IQuestionResult>(), 
                    key: question.title,
                    title: null
                }
                for(const i in result.data[key]) {
                    const arrItem = {
                        type: "string",
                        val: result.data[key][i],
                        key: parseInt(i),
                        title: null
                    }
                    questionResult.val.push(arrItem);
                }
            }
            else {
                let val = result.data[key];
                let title = null;
                
                if(question.parent.isPanel) {
                    const parentTitle = (question.parent as any).title;
                    val = result.data[key];
                    if(parentTitle) {
                        title = parentTitle
                    }
                }

                questionResult = { 
                    type: typeof(result.data[key]), 
                    val: val, 
                    key: question.title, 
                    title: title
                }
            }
            data.push(questionResult);
        }

        let objJsonStr = JSON.stringify(data);
        let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    
        if(refToken?.token.amlId && refToken?.sessionId) {

            const type = searchParams.get("type");

            if(type === "legal") {

                if(ref && sessionId && authToken) {
                    const blob : Blob = await surveyPDF.raw('blob') as unknown as Blob;
                    const base64str : string = await getBase64Str(blob);
                    const submitDataObj : ISubmitData = {
                        ref: ref ,
                        form_data: objJsonB64,
                        pdfBase64: base64str,
                        auth_session: authToken
                    }

                    await submit(submitDataObj, sessionId);
                }
            }
            else if(type === "individual") {

                window.sessionStorage.setItem("aml_data", objJsonB64);
                const blob : Blob = await surveyPDF.raw('blob') as unknown as Blob;
                const dokobitResult : IDokobitSigningResponse = await createDokobitSigning(blob, refToken?.token.amlId, refToken?.sessionId);
            
                if(dokobitResult.status === 'ok') {
                    const url = dokobitResult.signers[0].redirect_uri;
                    window.open(
                        url,
                        "_self"
                    );
                }
                else {
                    // error handling
                }
            }
            else {
                // error handling
                throw new Error("undefined type");
            }
            
        } 

    }, [refToken, context.language, searchParams, sessionId, ref]);

    useEffect(() => {
        
        const renderSurvey = async () => {

            const type = searchParams.get("type");

            if(type === "individual") {
                if(!surveyData.current) {
                    surveyData.current = require('../assets/individual_survey.json');
                }
            }
            else {
                // fetch pre fill roles
                if(refToken && sessionId && !surveyData.current) {               
                    surveyData.current = require('../assets/legalentity_survey.json');
                    surveyData.current = await legalRoles.current.setLegalRolePanels(surveyData.current, refToken.token.amlId, refToken.token.source, sessionId, context.language);
                }              
            }
            
            if(surveyModel.current) {
                surveyModel.current.locale = context.language;
            }
            else {
                surveyModel.current = new Model(surveyData.current);
                //surveyModel.current.showPreviewBeforeComplete = "showAllQuestions";
                // survey.addUsedLocales([ "is", "en" ]);
                surveyModel.current.locale = context.language;
                surveyModel.current.editText = appText ? appText?.SURVEY_EDIT_TEXT: "";
    
                surveyLocalization.locales["is"].requiredError = "Skilyrt svæði";
                surveyLocalization.locales["en"].requiredError = "Required field";
    
                // prefill
                let nameField = surveyModel.current.getQuestionByName("name");
                nameField.setPropertyValue('readOnly', true);
                nameField.defaultValue = refToken?.token.amlName;
    
                let ssnField = surveyModel.current.getQuestionByName("ssn");
                ssnField.setPropertyValue('readOnly', true);
                ssnField.defaultValue = refToken?.token.amlCode;
    
                let emailField = surveyModel.current.getQuestionByName("email");
                ssnField.setPropertyValue('readOnly', true);
                emailField.defaultValue = refToken?.token.amlEmail;
    
                let addressField = surveyModel.current.getQuestionByName("address");
                addressField.defaultValue = refToken?.token.amlAddress;
    
                let phoneField = surveyModel.current.getQuestionByName("phone");
                phoneField.defaultValue = refToken?.token.amlPhoneNumber;
    
                // roles
                if(type === "legal") {
                    surveyModel.current = legalRoles.current.setLegalRolePanelData(surveyModel.current);
                }
    
                surveyModel.current.onComplete.add((result, options) => {
                    savePDF(result, surveyData.current)
                });
            }

            setSurvey(surveyModel.current);
            setIsLoading(false);
        }

        if(refToken) {
            renderSurvey();
        }

    }, [refToken, savePDF, appText, context.language, searchParams, sessionId]);

    useEffect(() => {       
        
        const validate = async () => {         
            if(authToken && sessionId && ref && searchParams) {
            
                try {
                    
                    await validateToken(authToken);

                    const sessionRes : ISessionIdVerifyResponse = await validateToken(sessionId);
                    if(sessionRes.token_verified === true) {                    
                        const ref = window.sessionStorage.getItem("aml_ref_id");
                        if(ref && searchParams.has('type')) {
                            const type = searchParams.get("type");
                            if(type === 'individual' || type === 'legal') {
                                const token = await decryptRefToken(ref);
                                if(token) setRefToken(token);
                            }                  
                        }
                        else {
                            window.location.replace(window.location.origin);
                        }
                    }
                }
                catch(err) {
                    // token error
                    window.location.replace(window.location.origin);
                }         
            }
            else {
                window.location.replace(window.location.origin);
            }
        }

        validate();

    }, [ref, sessionId, searchParams, authToken]);

    useEffect(() => {
        const appText : IAppText = require(`../assets/${context.language}_app_text.json`);
        setAppText(appText)   
    }, [context.language]);

    const Item = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body1,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%'
    }));

    const errorContainer = () => {
        return <Typography variant="h5" color={"textPrimary"}>
                    {appText?.SURVEY_COMPLETE_ERROR}
                </Typography>
    }

    return (
        <Container className="content">
            { !isLoading && survey && !error && <Survey model={survey} /> }
            { isLoading && 
            <Box mt={5}>
                <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                    <Item>
                        {appText?.IS_LOADING}
                    </Item>
                    <Item>
                        <CircularProgress size={'5rem'} />
                    </Item>
                </Stack>          
            </Box> }
            <Box p={5} id="main-container">
                { error && errorContainer() }
            </Box>
        </Container>
    )
}

export default SurveyComponent