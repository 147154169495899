import { ILegalRolesResponse } from "../types/data-model";
import { getLegalEntityRoles } from "./api-data";
import { Model } from 'survey-core';

export class LegalRoles {
    
    
    private _roles : ILegalRolesResponse | null;
    
    public get roles() : ILegalRolesResponse {
        if(this._roles === null) {
            throw new Error("roles not defined");
        }
        return this._roles;
    }

    private set roles(v : ILegalRolesResponse) {
        this._roles = v;
    }
    
    constructor() {
        this._roles = null;
    }

    setLegalRolePanels = async (surveyJson: any, amlId: string, source: string, sessionId: string, language: string) : Promise<any> => {

        this._roles = await getLegalEntityRoles(amlId, source, sessionId);
            
        let panel = require('../components/assets/role_panel.json');

        if(panel && this._roles) {
       
            if(this._roles.roles.length > 0) {

                if(this._roles) {
                    if(language === 'is') {
                        this._roles.roles.sort((a: any,b: any) => (a.role.is < b.role.is ) ? 1 : ((a.role.is > b.role.is ) ? -1 : 0))
                    }
                    else {
                        this._roles.roles.sort((a: any,b: any) => (a.role.en < b.role.en ) ? 1 : ((a.role.en > b.role.en ) ? -1 : 0))
                    }
                }

                const removeEl = ["existingOwners", "realOwners", "boardMembers"];
                surveyJson.pages[4].elements = surveyJson.pages[4].elements.filter(function( obj: any ) {
                    return !removeEl.includes(obj.name);
                });

                for(const role of this._roles.roles) {
                    let p = JSON.parse(JSON.stringify(panel));
                    p["name"] = role.name;
                    p["title"].is = `${role.role.is} - ${role.name}`
                    p["title"].en = `${role.role.en} - ${role.name}`
                    p["description"].is = role.description.is
                    p["description"].en = role.description.en
                    let elements = p["elements"];
                    for(const el of elements) {
                        const elName = role.roleId + el.name;
                        el["name"] = elName;
                    }
                    // add to last page
                    surveyJson.pages[4].elements.unshift(p);
                }
            }           
        }           
        
        return surveyJson;
    }
    
    setLegalRolePanelData = (survey: Model): Model => {
    
        if(this.roles === null) {
            throw new Error("roles not defined");
        }

        for(let role of this.roles.roles) {
            let ssnField = survey.getQuestionByName(`${role.roleId}ssn`);
            if(role.ssn) {
                ssnField.setPropertyValue('readOnly', true);
                ssnField.defaultValue = role.ssn;
            }
            
            let nameField = survey.getQuestionByName(`${role.roleId}name`);
            nameField.setPropertyValue('readOnly', true);
            nameField.defaultValue = role.name;

            // if(role.role.is === 'Prókúruhafi'){
            //     let emailField = survey.getQuestionByName(`${role.roleId}email`);
            //     emailField.isRequired = true;
            //     let phoneField = survey.getQuestionByName(`${role.roleId}phone`);
            //     phoneField.isRequired = true;
            // }
        }

        return survey;
    }
}

